import React from 'react'

import Layout from '../components/layout'
import CorruptionOne from '../components/strategy/CorruptionOne'


const Work = props => (
  <Layout lang="pt" title="Política de Anticorrupção" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <CorruptionOne lang="pt" />
  </Layout>
)

export default Work
